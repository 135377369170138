import { createAction } from "redux-api-middleware";
import decamelizeKeysDeep from "decamelize-keys-deep";
import AppSettings from "../../config";
import MobileApplet from "../../interfaces/admin/mobile-applet";
import MobileSettings from "../../interfaces/admin/mobile-settings";
import MobilePublish from "../../interfaces/admin/mobile-publish";
import networkSelectors from "../../selectors/network";

export const GET_ICON_DATA_LOADING = "GET_ICON_DATA_LOADING";
export const GET_ICON_DATA_SUCCESS = "GET_ICON_DATA_SUCCESS";
export const GET_ICON_DATA_FAILURE = "GET_ICON_DATA_FAILURE";
export const GET_APPLETS_LOADING = "GET_APPLETS_LOADING";
export const GET_APPLETS_SUCCESS = "GET_APPLETS_SUCCESS";
export const GET_APPLETS_FAILURE = "GET_APPLETS_FAILURE";
export const PUT_APPLETS_LOADING = "PUT_APPLETS_LOADING";
export const PUT_APPLETS_SUCCESS = "PUT_APPLETS_SUCCESS";
export const PUT_APPLETS_FAILURE = "PUT_APPLETS_FAILURE";
export const PUT_APPLETS_CLEAR = "PUT_APPLETS_CLEAR";
export const GET_BRANDING_LOADING = "GET_BRANDING_LOADING";
export const GET_BRANDING_SUCCESS = "GET_BRANDING_SUCCESS";
export const GET_BRANDING_FAILURE = "GET_BRANDING_FAILURE";
export const PUT_BRANDING_LOADING = "PUT_BRANDING_LOADING";
export const PUT_BRANDING_SUCCESS = "PUT_BRANDING_SUCCESS";
export const PUT_BRANDING_FAILURE = "PUT_BRANDING_FAILURE";
export const PUT_BRANDING_CLEAR = "PUT_BRANDING_CLEAR";
export const GET_SETTINGS_LOADING = "GET_SETTINGS_LOADING";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";
export const PUT_SETTINGS_LOADING = "PUT_SETTINGS_LOADING";
export const PUT_SETTINGS_SUCCESS = "PUT_SETTINGS_SUCCESS";
export const PUT_SETTINGS_FAILURE = "PUT_SETTINGS_FAILURE";
export const PUT_SETTINGS_CLEAR = "PUT_SETTINGS_CLEAR";
export const GET_PUBLISH_LOADING = "GET_PUBLISH_LOADING";
export const GET_PUBLISH_SUCCESS = "GET_PUBLISH_SUCCESS";
export const GET_PUBLISH_FAILURE = "GET_PUBLISH_FAILURE";
export const PUT_PUBLISH_LOADING = "PUT_PUBLISH_LOADING";
export const PUT_PUBLISH_SUCCESS = "PUT_PUBLISH_SUCCESS";
export const PUT_PUBLISH_FAILURE = "PUT_PUBLISH_FAILURE";
export const PUT_PUBLISH_CLEAR = "PUT_PUBLISH_CLEAR";
export const POST_BUILD_LOADING = "POST_BUILD_LOADING";
export const POST_BUILD_SUCCESS = "POST_BUILD_SUCCESS";
export const POST_BUILD_FAILURE = "POST_BUILD_FAILURE";
export const POST_BUILD_CLEAR = "POST_BUILD_CLEAR";

export function putBranding({ banner, logo, color }: { banner: string; logo: string; color: string }) {
  const body = { banner, logo, color: color.replace(/^#/, "") };
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/branding`,
    method: "PUT",
    body: JSON.stringify(body),
    headers: networkSelectors.defaultHeaders,
    types: [PUT_BRANDING_LOADING, PUT_BRANDING_SUCCESS, PUT_BRANDING_FAILURE],
  });
}

export function putBrandingClear() {
  return (dispatch: any) =>
    dispatch({
      type: PUT_BRANDING_CLEAR,
    });
}

export function branding() {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/branding`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_BRANDING_LOADING, GET_BRANDING_SUCCESS, GET_BRANDING_FAILURE],
  });
}

export function putApplets(applets: Array<MobileApplet>) {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/applets`,
    method: "PUT",
    body: JSON.stringify({
      data: applets,
    }),
    headers: networkSelectors.defaultHeaders,
    types: [PUT_APPLETS_LOADING, PUT_APPLETS_SUCCESS, PUT_APPLETS_FAILURE],
  });
}

export function putAppletsClear() {
  return (dispatch: any) =>
    dispatch({
      type: PUT_APPLETS_CLEAR,
    });
}

export function applets() {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/applets`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_APPLETS_LOADING, GET_APPLETS_SUCCESS, GET_APPLETS_FAILURE],
  });
}

export function iconData() {
  return createAction({
    endpoint: `${AppSettings.ASSETS_URL}/font/icons.json`,
    method: "GET",
    types: [GET_ICON_DATA_LOADING, GET_ICON_DATA_SUCCESS, GET_ICON_DATA_FAILURE],
  });
}

export function settings() {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/settings`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_SETTINGS_LOADING, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE],
  });
}

export function putSettings(settings: MobileSettings) {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/settings`,
    method: "PUT",
    headers: networkSelectors.defaultHeaders,
    body: JSON.stringify({ data: settings }),
    types: [PUT_SETTINGS_LOADING, PUT_SETTINGS_SUCCESS, PUT_SETTINGS_FAILURE],
  });
}

export function putSettingsClear() {
  return (dispatch: any) =>
    dispatch({
      type: PUT_SETTINGS_CLEAR,
    });
}

export function getPublish() {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/publish`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_PUBLISH_LOADING, GET_PUBLISH_SUCCESS, GET_PUBLISH_FAILURE],
  });
}

export function putPublish(build: MobilePublish) {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/publish`,
    method: "PUT",
    headers: networkSelectors.defaultHeaders,
    body: JSON.stringify(decamelizeKeysDeep({ data: build }, "-")),
    types: [PUT_PUBLISH_LOADING, PUT_PUBLISH_SUCCESS, PUT_PUBLISH_FAILURE],
  });
}

export function putPublishClear() {
  return (dispatch: any) =>
    dispatch({
      type: PUT_PUBLISH_CLEAR,
    });
}

export function postBuild() {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/mobile/build`,
    method: "POST",
    headers: networkSelectors.defaultHeaders,
    types: [POST_BUILD_LOADING, POST_BUILD_SUCCESS, POST_BUILD_FAILURE],
  });
}

export function postBuildClear() {
  return (dispatch: any) =>
    dispatch({
      type: POST_BUILD_CLEAR,
    });
}
