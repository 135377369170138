import React from "react";
import styled from "styled-components";
import { Segment, Button } from "semantic-ui-react";
import { datadogRum } from "@datadog/browser-rum";
import AppSettings from "../config";
import { readVersion } from "../utils/functions";

if (AppSettings.DATADOG_RUM_TOKEN && AppSettings.DATADOG_RUM_APP_ID) {
  datadogRum.init({
    applicationId: AppSettings.DATADOG_RUM_APP_ID,
    clientToken: AppSettings.DATADOG_RUM_TOKEN,
    site: "datadoghq.com",
    service: "web_frontend",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    version: readVersion(),
  });
}

if (AppSettings.DATADOG_RUM_TOKEN && AppSettings.DATADOG_RUM_APP_ID) {
  datadogRum.startSessionReplayRecording();
}

class GOGovFatalError extends Error {
  constructor(message: any) {
    super(message); // (1)
    this.name = "GOGovFatalError"; // (2)
  }
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 40px;
`;

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    const manualError = new GOGovFatalError(`${error}`);
    datadogRum.addError(manualError, {
      pageStatus: "crashed",
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", minHeight: "75vh" }}>
          <Segment raised style={{ margin: "auto", borderRadius: 12, padding: 40, borderTop: "6px solid #DB2828" }}>
            <Title>{`Sorry, there was a problem rendering the application.`}</Title>
            <Subtitle>Please try refreshing the page.</Subtitle>
            <Button primary className='permitsButton' style={{ padding: "8px 20px" }} onClick={() => location.reload()}>
              Refresh Page
            </Button>
          </Segment>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
