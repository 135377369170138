import React, { useState } from "react";
import { Popup, Icon, Header, Divider, Button, Placeholder } from "semantic-ui-react";
import { ContactAvatar } from "../../permits/components/ContactAvatar";
import "./ContactConnectedProfilePopup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { PermitsContact } from "../../permits/types/PermitsContact";
import { getContact } from "../../../queries/contacts";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import network from "../../../selectors/network";
import { getCamel, getPhysicalAddressText, makeFullAddress } from "../../../utils/functions";
import { Person } from "../types/Person";
import { faBriefcase, faIdBadge, faLocationDot, faMailboxFlagUp, faPhone } from "@fortawesome/pro-regular-svg-icons";

interface IContactConnectedProfilePopupProps {
  contact?: PermitsContact;
  trigger: JSX.Element;
}

export default function ContactConnectedProfilePopup({ contact, trigger }: IContactConnectedProfilePopupProps) {
  // TODO: Add flags
  const flags = [];

  if (!contact || !contact.userId) return trigger;

  const [loadCitizen, setLoadCitizen] = useState<boolean>(false);
  const { site } = useParams<{ site: string }>();
  const networkHeaders = useSelector(network.defaultHeaders);
  const citizenQuery = useQuery(["citizen", contact.userId.toString()], getContact(networkHeaders), {
    enabled: loadCitizen,
  });
  const citizen = getCamel<Person | null>(citizenQuery.data, "data.attributes", null);

  if (!citizen) {
    return (
      <Popup
        style={{ padding: "0", border: "1px solid #DBDDDE", borderRadius: "8px", overflow: "hidden" }}
        basic
        position='bottom left'
        hoverable
        mouseEnterDelay={500}
        mouseLeaveDelay={200}
        trigger={trigger}
        onOpen={() => setLoadCitizen(true)}
      >
        <div className='contact-connected-profile-popup'>
          <div className='connected-profile-container'>
            <div>
              <Icon name='sync alternate' />
            </div>
            <span>Connected Profile</span>
          </div>
          <div style={{ width: "100%", height: "250px", padding: 16 }}>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
            <Divider />
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </div>
        </div>
      </Popup>
    );
  }

  const fullAddress = makeFullAddress(citizen.address, citizen.address2, citizen.city, citizen.state, citizen.zip);
  const physicalAddressText = getPhysicalAddressText(
    citizen.sameMailAndPhysicalAddress,
    citizen.homeAddress,
    citizen.homeAddress2,
    citizen.homeCity,
    citizen.homeState,
    citizen.homeZip,
    !!citizen.address,
  );

  return (
    <Popup
      style={{ padding: "0", border: "1px solid #DBDDDE", borderRadius: "8px", overflow: "hidden" }}
      basic
      position='bottom left'
      hoverable
      mouseEnterDelay={500}
      mouseLeaveDelay={200}
      trigger={trigger}
      onClick={(e: any) => e.stopPropagation()}
    >
      <div className='contact-connected-profile-popup'>
        <div className='connected-profile-container'>
          <div>
            <Icon name='sync alternate' />
          </div>
          <span>Connected Profile</span>
        </div>
        <div className='name-container'>
          <ContactAvatar size='32px' firstName={citizen.firstName} lastName={citizen.lastName} />
          <div>
            <Header sub className='secondaryText'>
              {contact.contactType}
            </Header>
            <Header>
              {citizen.firstName} {citizen.lastName}
            </Header>
          </div>
        </div>
        <Divider />
        <div className='bottom-container'>
          <div className='information-container'>
            {citizen.email && (
              <Popup
                content='Email Address'
                inverted
                mouseEnterDelay={500}
                position='left center'
                trigger={
                  <div>
                    <div className='icon-container'>
                      <Icon name='mail outline' />
                    </div>
                    <span>{citizen.email}</span>
                  </div>
                }
              />
            )}
            {citizen.phone && (
              <Popup
                content='Phone Number'
                inverted
                mouseEnterDelay={500}
                position='left center'
                trigger={
                  <div>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <span>{citizen.phone}</span>
                  </div>
                }
              />
            )}
            {fullAddress != "" && (
              <Popup
                content='Mailing Address'
                inverted
                mouseEnterDelay={500}
                position='left center'
                trigger={
                  <div>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={faMailboxFlagUp} />
                    </div>
                    <span>{fullAddress}</span>
                  </div>
                }
              />
            )}
            {!!physicalAddressText && (
              <Popup
                content='Physical Address'
                inverted
                mouseEnterDelay={500}
                position='left center'
                trigger={
                  <div>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>
                    <span>
                      {citizen.sameMailAndPhysicalAddress ? <i>{physicalAddressText}</i> : physicalAddressText}
                    </span>
                  </div>
                }
              />
            )}
            {citizen.companyName && (
              <Popup
                content='Company Name'
                inverted
                mouseEnterDelay={500}
                position='left center'
                trigger={
                  <div>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={faBriefcase} />
                    </div>
                    <span>{citizen.companyName}</span>
                  </div>
                }
              />
            )}
            {citizen.licenseNumber && (
              <Popup
                content='License Number'
                inverted
                mouseEnterDelay={500}
                position='left center'
                trigger={
                  <div>
                    <div className='icon-container'>
                      <FontAwesomeIcon icon={faIdBadge} />
                    </div>
                    <span>{citizen.licenseNumber}</span>
                  </div>
                }
              />
            )}
          </div>
          <div className='action-buttons'>
            <Link to={`/${site}/contacts/${contact.userId}`}>
              <Button secondary>View Profile</Button>
            </Link>
            {!!flags.length && <Button className='button-warning'>{flags.length} Flags</Button>}
          </div>
        </div>
      </div>
    </Popup>
  );
}
